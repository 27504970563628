var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import retry from '~/helpers/retry';
import urlFromSlug from './url-from-slug';
export default function cmsFetch(path) {
    return __awaiter(this, void 0, void 0, function* () {
        const url = path.replace(/[^?]+/, urlFromSlug);
        try {
            return (yield retry(() => fetch(url))).json();
        }
        catch (err) {
            return Promise.reject(new Error(`Failed to fetch ${path}: ${err}`));
        }
    });
}
export function cmsPost(path, payload, method) {
    return __awaiter(this, void 0, void 0, function* () {
        const url = path.replace(/[^?]+/, urlFromSlug);
        const params = new window.URLSearchParams(payload);
        const qs = method.toLowerCase() === 'delete' ? `?${params}` : '';
        try {
            return (yield fetch(`${url}${qs}`, {
                method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })).json();
        }
        catch (_a) {
            return Promise.reject(new Error(`Failed to ${method} ${url}${qs}`));
        }
    });
}
